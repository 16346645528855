$accordion-icon-color: #7fb2e5;

.block.accordion {
  .section.full-width {
    height: auto;
    .title {
      font-size: 2.35rem !important;
      font-weight: bold;
      line-height: 3rem;

      .DraftEditor-root {
        .public-DraftEditorPlaceholder-root {
          width: 100%;
          font-size: 2.35rem;
        }
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      padding: 0.5em;
    }

    .card {
      padding: 2em;
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding: 0.5em;
      }

      .card-body {
        @media (min-width: #{map-get($grid-breakpoints, md)+1}) {
          max-height: 70vh;
          overflow-y: scroll;
        }

        padding: 0 3em;

        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          padding: 0;
        }

        .accordion-item {
          margin-top: 0.5em;

          .dragsubblock {
            top: -7px;
            left: -1.5rem;
          }

          .ui.basic.button.delete-button {
            top: -7px;
            right: 0;
            left: unset;

            &:focus {
              outline: 1px solid $focus-outline-color;
            }
          }

          .accordion-header {
            padding: 0.5em 0;
            border-bottom: 1px solid $gray-border;
            color: $primary;
            font-size: 1.2rem;
            font-weight: bold;

            & > div {
              width: 100%;
            }

            p {
              margin: 0;
              text-align: left;
            }

            button {
              padding: 0 0.4em;
              border: none;
              background: none;

              &:focus {
                outline: 1px solid $focus-outline-color;
              }

              .icon {
                width: 1.2rem;
                fill: $accordion-icon-color;
              }
            }
          }

          &.subblock-view {
            .accordion-header {
              button {
                display: flex;
                align-items: center;
                color: $primary;
                font-size: 1.2rem;
                font-weight: bold;

                .icon {
                  flex-shrink: 0;
                  margin-right: 0.5em;
                }
              }
            }
          }

          .subblock-edit {
            .accordion-header {
              display: flex;
              align-items: center;

              .title {
                color: $primary;
              }

              button {
                padding: 0 0.4em;
                margin: 0 0.2em;

                .icon {
                  width: 1.2rem;
                  height: 1.2rem;
                  fill: $accordion-icon-color;
                }
              }
            }
          }

          .accordion-content {
            overflow: hidden;
            height: auto;
            max-height: 0;
            padding: 0;
            padding-left: 3em;

            transition: max-height 0.3s ease-in-out;

            &.open {
              max-height: 1000vh;
            }

            .accordion-inner {
              padding: 2em 0;
            }

            .link-more {
              text-align: right;

              a {
                font-weight: bold;

                svg {
                  width: 1.2em;
                  fill: $link-color;
                }
              }
            }
          }
        }
      }
    }

    &.is-edit-mode {
      .card {
        .card-body {
          //per mostrare la barra di formattazione di draft-js
          padding-left: 10em;
          margin-left: -7em;
        }
      }
    }
  }
}
