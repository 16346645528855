//site
$primary-h: 210 !default;
$primary-s: 100 !default;
$primary-b: 80 !default;

$primary: #c93e16;
$secondary: #183250;
$tertiary: #30373d;
$white: #fff;
$text-color: #191919;
// $tertiary-text: $white;

$body-color: $text-color;
$header-center-text-color: $secondary;
$header-brand-text-color: #191919;
$header-bg-color: $primary;
$header-center-bg-color: $white;
// Footer color variable
$footer-bg-color: $tertiary;
// Setting these bootstrap/bootstrap-italia variables are not working
// Need to customize by hand, would be great to solve in base product
// $header-slim-bg-color: $secondary;
// $flag-icon-color: $primary;

//$headings-color: $primary;
//$semibold: 600;
