.it-footer {
  .it-footer-main {
    .it-brand-wrapper {
      display: flex;
      align-items: center;
      gap: var(--bs-gutter-x);
      @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        flex-direction: column;
        align-items: flex-start;
      }

      .nextGenerationEULogo {
        width: auto;
        height: 75px;
        aspect-ratio: 167 / 41;
      }

      a {
        h2 {
          font-size: 1.777778rem;
        }

        h3 {
          font-weight: 600;
        }

        .icon {
          width: 75px;
          height: 75px;
          margin-right: 27px;
        }
      }
    }

    h4 {
      display: flex;
      align-items: center;
      margin-bottom: 0.889rem;
      color: $white;
      font-size: 16px;
      text-transform: uppercase;

      a {
        font-weight: 700;

        &.underlined {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    a {
      color: $white;

      &:hover:not(.disabled) {
        text-decoration: underline;
      }
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    .it-brand-wrapper {
      a {
        display: inline-flex;
      }
    }

    .link-list-wrapper {
      ul {
        li {
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .social {
      .text-white {
        .icon-white {
          fill: currentcolor !important;
        }

        .icon {
          fill: currentcolor !important;
        }
      }

      .list-inline-item {
        margin-bottom: 1em;

        &,
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }

  .it-footer-small-prints {
    a {
      word-break: normal;
    }

    a,
    button,
    a:hover:not(.disabled),
    button:hover:not(.disabled) {
      text-decoration: underline;
    }

    .in-evidence {
      font-weight: bold;
    }

    button {
      padding: 0;
      border: none;
      margin: 0;
      background: none;
      color: #fff;
      font-size: 16px;

      &:focus {
        outline: none;
      }
    }
  }
}
