.it-header-navbar-wrapper {
  background: $primary;
  color: $secondary;
}
// Someday we'll fix this in base product...
@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper nav {
    padding-left: 6px;
    padding-right: 6px;
    justify-content: center;
    .custom-navbar-toggler {
      transform: translateX(0);
    }
  }
  .it-header-wrapper .it-nav-wrapper .it-header-navbar-wrapper {
    left: 12px;
    width: 40px;
    height: 40px;
  }
}
@media (max-width: #{map-get($grid-breakpoints, sm)}) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 42px;
  }
}
@media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 18px;
  }
}

#navigation .menu-wrapper .it-brand-wrapper {
  padding-left: 24px;
  a .icon {
    width: 115px;
    height: auto;
  }
  h2,
  h3 {
    color: $text-color;
  }
}

// Replica vecchio sito, molto meh, commentato per ora, vediamo in review
// .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
//   background: $tertiary;
// }
.it-nav-wrapper
  .it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper {
  a {
    display: flex;
    align-items: center;
    h3 {
      font-size: 1.15rem;
    }
  }
  a .icon {
    width: auto;
    // height: auto;
  }
  @media (min-width: 992px) {
    a .icon {
      width: 230px;
      height: auto;
    }
  }
  .it-brand-text .no_toc {
    color: $header-brand-text-color !important;
  }
}

.it-header-slim-wrapper {
  background: $secondary;
}

.it-footer .it-footer-main {
  .it-brand-wrapper a .icon {
    width: auto;
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    .it-brand-wrapper a .icon {
      width: 230px;
      height: auto;
    }
  }
  background-color: $footer-bg-color;
  .it-brand-wrapper a {
    display: flex;
    align-items: center;
  }
}

.it-footer-small-prints {
  background-color: shade-color($footer-bg-color, 5%);
}

.scroll-to-top .btn,
.scroll-to-top .subsite-header .text a,
.subsite-header .text .scroll-to-top a {
  background-color: $primary !important;
  border-color: $primary !important;
}

:focus,
.form-check [type='checkbox']:focus + label,
.form-check [type='radio']:focus + label,
.toggles label input[type='checkbox']:focus + .lever,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label,
.toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + .lever {
  border-color: $tertiary !important;
  box-shadow: 0 0 0 2px $tertiary !important;
  outline: none;
}
