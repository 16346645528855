.public-ui {
  .block.alertblock,
  .alertblock {
    .full-width {
      height: auto;
    }

    .draftjs-buttons a {
      background-color: $white;
      color: $body-color;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }

    .bg-alert-danger {
      background-color: $alert-danger;
      color: $alert-danger-text;

      a {
        color: $alert-danger-text;
      }
    }

    .bg-alert-warning {
      background-color: $alert-warning;
      color: $alert-warning-text;

      a {
        color: $alert-warning-text;
      }
    }

    .bg-alert-info {
      background-color: $alert-info;
      color: $alert-info-text;

      a {
        color: $alert-info-text;
      }
    }

    .row {
      margin-right: calc(var(--bs-gutter-x) * -0.25);
      margin-left: calc(var(--bs-gutter-x) * -0.25);

      .col {
        padding-right: calc(var(--bs-gutter-x) * 0.25);
        padding-left: calc(var(--bs-gutter-x) * 0.25);
      }
    }

    p {
      margin: 0;
    }

    img.left-image {
      max-width: 80%;
      object-fit: unset;

      &.size {
        &-m {
          max-width: 50%;
        }

        &-s {
          max-width: 30%;
        }
      }
    }

    .image-col {
      text-align: center;
    }
  }
}
