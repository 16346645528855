.block.numbersBlock .background-image:after {
  background-color: $tertiary;
}

.block.count_down .public-ui .background-image:after {
  background-color: $tertiary;
}

.flag-icon {
  background: $primary;
}
