.siteroot,
body.public-ui.contenttype-lrf {
  .block-editor-title,
  .documentFirstHeading {
    @include visually-hidden;
  }
}

.sidebar-container-enter-done {
  z-index: 150000 !important;
}

body.subsite.subsite-root {
  &,
  .public-ui {
    .documentFirstHeading {
      @include visually-hidden;
    }
    #briciole {
      display: none;
    }
  }
}
