.contenttype-document {
  @media print {
    .PageHeaderWrapper {
      &.mb-4 {
        margin-bottom: 0rem;
      }

      .title-description-wrapper {
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }
}
