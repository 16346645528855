//navbar spacing fix

.public-ui {
  .it-header-wrapper {
    .navbar {
      .container-fluid {
        padding: 0px;

        .navbar-secondary {
          li.nav-item:last-child {
            a.nav-link {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}

//

.documentFirstHeading {
  border: none;

  &:before {
    content: none;
  }
}

.documentDescription {
  @include rem-size(font-size, 24);
  margin-top: 1.5rem;
  color: $body-color;
  line-height: 2rem;
  white-space: pre-wrap;
}

a {
  cursor: pointer;

  &[href^='mailto'],
  &[target='_blank'],
  &[rel='noopener noreferreer'] {
    word-break: break-word;
  }
}

.listing-item img {
  max-width: unset;
  margin-right: unset;
}

.public-ui .text-secondary {
  color: $secondary-text !important;
}

::selection {
  background-color: default;
}

.public-ui {
  a.btn.btn-primary {
    svg {
      fill: $primary-text;
    }
  }

  a.btn.btn-secondary {
    svg {
      fill: $secondary-text;
    }
  }
}

iframe {
  max-width: 100%;
  border: none;
}

.public-ui,
.cms-ui {
  blockquote {
    position: relative;
    margin: 1.5rem 2rem;
    font-family: $font-family-serif;

    &:before {
      position: absolute;
      top: -1.8rem;
      left: -2rem;
      display: block;
      height: 0;
      color: $neutral-2-b2;
      content: '“';
      font-size: 400%;
    }
  }

  p.callout {
    max-width: none;
    border-left-width: 0.4rem;

    p,
    .public-DraftStyleDefault-block {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .callout-bg {
    padding: 1.25rem;
    margin: 1.25rem 0;
    background-color: $primary-c1;

    p,
    .public-DraftStyleDefault-block {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .draftjs-text-smaller {
    font-size: 0.75em;
  }

  .draftjs-text-larger {
    font-size: 1.75em;
  }

  .draftjs-buttons {
    margin: 0;
    clear: both;

    a {
      display: inline-block;
      padding: 1em 2em;
      border-radius: $btn-border-radius;
      margin: 0.5em 1em 0.5em 0;
      background-color: $primary;
      color: $primary-text;
      font-weight: 700;
      text-align: center;
      text-decoration: none;

      &:hover,
      &:active {
        background-color: darken($primary, 8);
        color: $primary-text;
        font-weight: 700;
      }
      &.link-anchorlink-theme {
        color: $primary-text !important;
      }
    }
    /*
      Safari rendering issue workaround - B#60364
      Safari rendering fix, icons and fonts coming late means shenanigans for Safari rendering, as
      Safari is lazy and slow in loading fonts and icons dynamically.
      Problem: Safari (tested on Safari 17.6) sometimes fails to calculate the layout correctly
      when dealing with dynamic content such as lazy-loaded icons or fonts, combined with
      `display: inline-block`. This can result in improperly positioned elements
      until the page is re-rendered or refocused.

      Solution: To fix this issue:
      1. Replace `display: inline-block` with `display: inline-flex`. This ensures Safari
          handles the box model and inline context properly, especially when the element's
          size or alignment depends on dynamic content.
      2. Add `position: relative;` to force a recalculation of layout, resolving rendering quirks.

      Background: Safari's WebKit engine has known issues with `inline-block` combined
      with delayed content rendering, such as lazy-loaded icons. Switching to `inline-flex`
      better aligns with modern CSS rendering engines, while `position: relative;`
      ensures the layout is recalculated.

      References:
      - CSS-Tricks: What Forces Layout or Reflow: https://css-tricks.com/what-forces-layout-reflow/
      - WebKit Bug Reports on `inline-block` rendering issues with dynamic content.
      - Testing and observations on Safari 17.6 behavior with lazy-loaded assets.
    */
    @supports (-webkit-appearance: none) {
      a {
        display: inline-flex; /* Evita problemi con inline-block */
        position: relative; /* Forza Safari a calcolare il layout reflow correttamente */
      }
    }
  }

  div[class^='draftJsToolbar__toolbar__'] {
    line-height: 1rem;

    .icon {
      font-size: 1rem;
    }

    .ui.input > input {
      height: auto;
      font-size: 0.8rem;
    }
  }
}

#text-body {
  .full-width {
    position: static;
    left: auto !important;
    width: 100% !important;
    max-width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.select-all-cts.btn.btn-link.btn-mini {
  padding: 0;
}

.loading {
  .progress-spinner {
    margin-right: auto;
    margin-left: auto;
  }
}

.public-ui,
* {
  .fa,
  .fas,
  .far,
  .fal,
  .fad,
  .fab {
    display: inline-block;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
  }

  .fa-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -0.0667em;
  }

  .fa-xs {
    font-size: 0.75em;
  }

  .fa-sm {
    font-size: 0.875em;
  }

  .fa-1x {
    font-size: 1em;
  }

  .fa-2x {
    font-size: 2em;
  }

  .fa-3x {
    font-size: 3em;
  }

  .fa-4x {
    font-size: 4em;
  }

  .fa-5x {
    font-size: 5em;
  }

  .fa-6x {
    font-size: 6em;
  }

  .fa-7x {
    font-size: 7em;
  }

  .fa-8x {
    font-size: 8em;
  }

  .fa-9x {
    font-size: 9em;
  }

  .fa-10x {
    font-size: 10em;
  }

  svg.fa-icon {
    width: 1em;
    fill: currentColor;
  }

  .icon.fa-icon.placeholder {
    //to prevent layout shift
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
  }
}

//table overflow mobile
@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .public-ui table.ui.table {
    display: block;
    overflow: auto;

    th {
      white-space: nowrap;
    }
  }
}

// fix footer line and columns without title
.it-footer-main {
  .row > div {
    h4:empty:before {
      content: '\200b';
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        content: none;
      }
    }

    h4 {
      padding-bottom: 0.889rem;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        &:empty {
          padding-bottom: 0 !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .row > div ~ div h4:empty {
    position: relative;
    border-bottom: none;

    &::after {
      position: absolute;
      bottom: -1px;
      left: -$grid-gutter-width;
      width: calc(100% + $grid-gutter-width);
      height: 1px;
      background: hsla(0, 0%, 100%, 0.5);
      content: '';
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        content: none;
      }
    }
  }
}

//fix footer styles for volto-editablefooter footerTop field
.it-footer .it-footer-main .it-brand-wrapper .footerTop,
.footer-configuration-widget .footer-top-segment .block.gridBlock .grid-items {
  h2 {
    margin-bottom: 0;
    font-size: 1.777778rem;
    font-weight: 600;
    letter-spacing: unset;
    line-height: 1.1;
  }

  h3 {
    font-weight: 600;
  }

  .block.image img {
    width: auto;
    max-height: 75px;
  }

  .block.gridBlock {
    .col {
      &:has(.block.image) {
        width: auto;
        flex: 0 0 auto;
      }
    }
  }
}

.bg-light {
  --bs-light-rgb: #{red($primary-a0)}, #{green($primary-a0)},
    #{blue($primary-a0)};
}

.public-ui {
  h1 {
    @include rem-size(font-size, 48);
  }

  h5 {
    @include rem-size(font-size, 24);
  }
}

//search weekday fix
.DayPicker {
  .DayPicker_weekHeader {
    .DayPicker_weekHeader_ul {
      padding-left: 0;
    }
  }
}

.public-ui
  .it-header-slim-wrapper
  .it-header-slim-wrapper-content
  .it-header-slim-right-zone.header-slim-right-zone
  .login-button {
  @media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width:#{map-get($grid-breakpoints, xl)}) {
    flex: unset;
  }
}
