.single-block.subblock-edit {
  .dragsubblock,
  .ui.basic.button.delete-button {
    z-index: 3;
    width: 1.8rem !important;
    height: 1.8rem !important;
    padding: 0.3rem;
    border-radius: 100%;
    background-color: #8bb3b5 !important;
    line-height: 1rem;
    text-align: center;

    svg:not(:hover) {
      fill: #fff !important;
    }

    &:hover,
    &:active {
      background-color: #a4dee1 !important;

      svg {
        fill: #000 !important;
      }
    }
  }

  .ui.basic.button.delete-button {
    &:hover,
    &:active {
      background-color: #e40166 !important;
      color: #fff;

      svg {
        fill: #fff !important;
      }
    }
  }

  .dragsubblock {
    top: -0.75rem;
    left: -0.55rem;
  }

  .ui.basic.button.delete-button {
    top: -0.7rem;
    width: unset;
    height: unset;
    padding: 0.36rem;
    text-align: center;
  }
}
